
import { defineComponent } from 'vue'
import axios from 'axios'
import debounce from 'lodash.debounce'
import { plainToInstance } from 'class-transformer';

import ClinicalEventCriterion from './ClinicalEventCriterion.vue'
import { ClinicalEventCriterionModel } from './../model/ClinicalEventCriterionModel'
import PatientCriteria from './PatientCriteria.vue'
import { PatientCriteriaModel } from '@/model/PatientCriteriaModel'
import AddCriteriaDropdown from './AddCriteriaDropdown.vue'
import ReportChart from './ReportChart.vue';

export default defineComponent({
    name: 'TreatmentComparison',
    components: {
        ClinicalEventCriterion,
        PatientCriteria,
        AddCriteriaDropdown,
        ReportChart
    },
    data() {
        return {
            loaded: false,
            cohortCriteria: new PatientCriteriaModel(),
            groups: [

            ],
            includeNoTreatmentOption: false,
            outcomes: new Array<ClinicalEventCriterionModel>(),
            cohortSize: "0",
            numberFormat: new Intl.NumberFormat('en-US'),

            // apex
            series: [{data: []}],
            colors: ['#25ACB8', '#F8A73D', '#6DBBA1', '#DE8345', '#8D3057', '#8072AC']
        }
    },
    mounted() {
        // this.load()
    },
    computed: {
        // Used to monitor changes in selection criteria and trigger API interactions
        patientCriteriaTrigger() {
            const selectionHash: any = this.cohortCriteria.getForAPI()
            // this.save()
            this.updateCohortSize()
            return selectionHash;
        },
        // conditionsTrigger() {
        //     const reportRequest = this.getReportRequest()
        //     this.updateOutcomes(reportRequest);
        //     return reportRequest;
        // }
    },
    methods: {
        load() {
            axios.get('api/ui-state/treatments/dev')
            .then(response => {
                // console.log("Load");
                const model = response.data
                if (model && model.cohortCriteria) {
                    this.cohortCriteria.setAll(model.cohortCriteria)
                    // console.log("getForApi after plainToClass", this.cohortCriteria.getForAPI());

                    this.groups.length = 0
                    model.groups.forEach((group: any) => {
                        const gC = new PatientCriteriaModel()
                        if (group.criteria) {
                            gC.setAll(group.criteria)
                        }
                        this.groups.push({
                            name: group.name,
                            criteria: gC
                        })
                    })
                    this.includeNoTreatmentOption = model.includeNoTreatmentOption

                    this.outcomes = plainToInstance(ClinicalEventCriterionModel, model.outcomes)
                    this.loaded = true
                } else {
                    this.cohortCriteria.eventCriteria.push()
                    this.loaded = true
                }
            })
        },
        save() {
            if (!this.loaded) {
                return
            }
            const model = {
                cohortCriteria: this.cohortCriteria,
                groups: this.groups,
                includeNoTreatmentOption: this.includeNoTreatmentOption,
                outcomes: this.outcomes,
            }
            // console.log(model);
            axios.post('api/ui-state/treatments/dev', model);
            console.log("saved:", this.cohortCriteria.gender);
        },
        addOutcome(display: string, eclBinding: string) {
            let outcome = new ClinicalEventCriterionModel(display, eclBinding)
            let colorsUsed = new Array<string>()
            this.outcomes.forEach(outcome => {
                if (outcome.color) {
                    colorsUsed.push(outcome.color)
                }
            })
            const colorsLeft = this.colors.filter(c => !colorsUsed.includes(c))
            console.log("colorsLeft", colorsLeft);
            
            if (colorsLeft.length != 0) {
                outcome.color = colorsLeft[0]
            }
            this.outcomes.push(outcome)
        },
        addGroup() {
            const model = new PatientCriteriaModel()
            model.treatment = true
            this.groups.push({name: "Treatment X", criteria: model})
        },
        updateCohortSize: function() {
            // eslint-disable-next-line
            const context = this;
            debounce(function() {
                console.log('updating cohort size')
                axios.post('api/cohorts/select', context.cohortCriteria.getForAPI())
                    .then(response => {
                        context.cohortSize = context.numberFormat.format(response.data.totalElements);
                    })
            }, 100)
        },
        runReport: function() {
            const reportRequest = this.getReportRequest()
            this.updateOutcomes(reportRequest);
            return reportRequest;
        },
        updateOutcomes: function(report: any) {
            if (report.groups && report.groups.length == 2 && report.groups[1].length) {
                this.$refs.chart.fetchReport(report)
            }
        },
        getReportRequest: function() {
            const report = {} as any;
            report.criteria = this.cohortCriteria.getForAPI()

            const patientGroups = new Array<unknown>();
            this.groups.forEach(group => {
                const groupCriteria = {} as any;
                patientGroups.push(groupCriteria)
                groupCriteria.name = group.name;
                groupCriteria.criteria = group.criteria.getForAPI()
            })
            if (this.includeNoTreatmentOption) {
                const negativeGroupCriteria = {} as any;
                negativeGroupCriteria.name = "All other patients";
                const exclusionCriteria = [] as Array<any>
                patientGroups.forEach(patientGroup => {
                    exclusionCriteria.push(patientGroup.criteria)
                })
                negativeGroupCriteria.criteria = {
                    exclusionCriteria: exclusionCriteria
                }
                patientGroups.push(negativeGroupCriteria)
            }

            const outcomesRequest = new Array<unknown>();
            const colors = new Array<string>();
            this.outcomes.forEach(outcome => {
                if (outcome.isFilled()) {
                    colors.push(outcome.color)
                    // Ensure outcomes happen after treatments
                    // -1 here means an unbounded search in the future
                    outcome.withinDaysAfterPreviouslyMatchedEvent = -1
                    const outcomeCriteria = {} as any;
                    outcomeCriteria.criteria = {
                        eventCriteria: [outcome.getForAPI()]
                    }
                    outcomeCriteria.name = outcome.display
                    outcomesRequest.push(outcomeCriteria)
                }
            })
            report.groups = [patientGroups, outcomesRequest];
            report.colors = colors
            return report;
        },
    }
})
