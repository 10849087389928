
import { defineComponent } from 'vue'

export default defineComponent({
    name: "AddCriteriaDropdown",
    props: {
        treatment: Boolean,
        label: {
            type: String,
            default: "Add Requirement"
        }
    },
})
