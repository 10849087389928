
import { defineComponent } from 'vue'
import axios from 'axios'
import debounce from 'lodash.debounce'
import { plainToInstance } from 'class-transformer';

import ClinicalEventCriterion from './ClinicalEventCriterion.vue'
import { ClinicalEventCriterionModel } from './../model/ClinicalEventCriterionModel'
import PatientCriteria from './PatientCriteria.vue'
import { PatientCriteriaModel } from '@/model/PatientCriteriaModel'
import AddCriteriaDropdown from './AddCriteriaDropdown.vue'
import ReportChartLongitudinal from './ReportChartLongitudinal.vue';

export default defineComponent({
    name: 'LongitudinalPage',
    components: {
        ClinicalEventCriterion,
        PatientCriteria,
        AddCriteriaDropdown,
        ReportChartLongitudinal
    },
    data() {
        return {
            loaded: false,
            cohortCriteria: new PatientCriteriaModel(),
            startDate: "",
            endDate: "",
            granularity: "YEAR",
            groups: [
                {
                    name: "Everyone",
                    criteria: new PatientCriteriaModel()
                }
            ],
            outcomes: new Array<ClinicalEventCriterionModel>(),
            outcomesIncludeHistory: false,
            eclHistory: " {{ +HISTORY }}",
            cohortSize: "0",
            numberFormat: new Intl.NumberFormat('en-US'),

            hideChart: false
        }
    },
    watch: {
        outcomesIncludeHistory() {
            console.log("outcomesIncludeHistory");
            
            this.outcomes.forEach(outcome => {
                var postfix = this.eclHistory
                if (!this.outcomesIncludeHistory) {
                    postfix = ""
                }
                this.$set(this.outcomes[this.outcomes.indexOf(outcome)], 'historyECL', postfix)
            })
        }
    },
    mounted() {
        // this.load()
        // this.outcomes.push(new ClinicalEventCriterionModel('Clinical Finding', '<404684003', '195967001'))// Asthma
        // this.outcomes.push(new ClinicalEventCriterionModel('Clinical Finding', '<404684003', '52448006'))// dementia
        // this.outcomes.push(new ClinicalEventCriterionModel('Clinical Finding', '<404684003', '13645005'))// copd
        // this.outcomes.push(new ClinicalEventCriterionModel('Clinical Finding', '<404684003', '64859006'))// osteoporosis
        // this.outcomes.push(new ClinicalEventCriterionModel('Clinical Finding', '<404684003', '46635009'))// diabetes 1
        // this.outcomes.push(new ClinicalEventCriterionModel('Clinical Finding', '<404684003', '44054006'))// diabetes 2
    },
    computed: {
        timepoints() {
            console.log("building timepoints");
            
            const points = [] as Array<Date>
            // int year

            let current = this.parseDate(this.startDate) as Date
            let end = this.parseDate(this.endDate) as Date
            if (current && end) {
                let i = 0
                const a = current.getTime()
                const b = end.getTime()
                while (current.getTime() <= end.getTime() && i++ < 1000) {
                    points.push(new Date(current.getTime()))
                    if (this.granularity == "YEAR") {
                        current.setUTCFullYear(current.getUTCFullYear() + 1)
                    } else if (this.granularity == "MONTH") {
                        current.setUTCMonth(current.getUTCMonth() + 1)
                    } else if (this.granularity == "DAY") {
                        current.setUTCDate(current.getUTCDate() + 1)
                    } else if (this.granularity == "HOUR") {
                        current.setUTCHours(current.getUTCHours() + 1)
                    }
                }
            }
            return points
        }
    },
    methods: {
        parseDate(input: string) {
            const regex = new RegExp('[0-9]{4}-[0-9]{2}-[0-9]{2}');
            if (input && regex.test(input)) {
                const parts = input.split('-')
                // 2001-01-01T00:00:00.000Z
                
                return new Date(parts[0], Number.parseInt(parts[1]) -1, parts[2])
            }
            return null
        },
        load() {
            axios.get('api/ui-state/longitudinal/dev')
            .then(response => {
                // console.log("Load");
                const model = response.data
                if (model && model.cohortCriteria) {
                    this.cohortCriteria.setAll(model.cohortCriteria)
                    // console.log("getForApi after plainToClass", this.cohortCriteria.getForAPI());

                    this.groups.length = 0
                    model.groups.forEach((group: any) => {
                        const gC = new PatientCriteriaModel()
                        if (group.criteria) {
                            gC.setAll(group.criteria)
                        }
                        this.groups.push({
                            name: group.name,
                            criteria: gC
                        })
                    })

                    this.outcomes = plainToInstance(ClinicalEventCriterionModel, model.outcomes)
                    this.outcomesIncludeHistory = model.outcomesIncludeHistory
                    this.loaded = true
                } else {
                    this.cohortCriteria.eventCriteria.push()
                    this.loaded = true
                }
            })
        },
        save() {
            if (!this.loaded) {
            // if (10 * 10 == 100 || !this.loaded) {
                return
            }
            const model = {
                cohortCriteria: this.cohortCriteria,
                groups: this.groups,
                outcomes: this.outcomes,
                outcomesIncludeHistory: this.outcomesIncludeHistory
            }
            // console.log(model);
            axios.post('api/ui-state/longitudinal/dev', model);
            console.log("saved:", this.cohortCriteria.gender);
        },
        addOutcome(display: string, eclBinding: string) {
            const model = new ClinicalEventCriterionModel(display, eclBinding)
            if (this.outcomesIncludeHistory) {
                model.historyECL = this.eclHistory
            }
            this.outcomes.push(new ClinicalEventCriterionModel(display, eclBinding))
        },
        removeOutcome(outcome: ClinicalEventCriterionModel) {
            if (this.outcomes) {
                const index = this.outcomes.indexOf(outcome)
                if (index >= 0) {
                    this.$delete(this.outcomes, index)
                }
            }
        },
        addGroup() {
            this.groups.push({name: "", criteria: new PatientCriteriaModel()})
        },
        updateCohortSize: function() {
            // eslint-disable-next-line
            const context = this;
            debounce(function() {
                // console.log('updating cohort size')
                axios.post('api/cohorts/select', context.cohortCriteria.getForAPI())
                    .then(response => {
                        context.cohortSize = context.numberFormat.format(response.data.totalElements);
                    })
            }, 100)
        },
        updateOutcomes: function(report: any) {
            if (report.groups && report.groups.length == 2 && report.groups[1].length) {
                this.$refs.chart.fetchReport(report)
            }
        },
        async run() {
            const reportRequests = [] as Array<any>
            let startTimepoint: Date

            this.timepoints.forEach(endTimepoint => {
                if (startTimepoint) {
                    reportRequests.push(this.getReportRequest(startTimepoint, endTimepoint, startTimepoint.getUTCFullYear() + ""))
                }
                startTimepoint = endTimepoint
            })
            // console.log("this.timepoints", this.timepoints);
            // console.log("reportRequests", reportRequests);
            
            this.$refs.chart.fetchReports(reportRequests)
        },
        getReportRequest: function(startDate: Date, endDate: Date, label: string) {
            const report = {} as any;
            report.name = label
            report.criteria = this.cohortCriteria.getForAPI()

            const patientGroups = new Array<unknown>();
            const groupCriteria = {} as any;
            patientGroups.push(groupCriteria)

            const outcomesRequest = new Array<unknown>();
            const colors = new Array<string>();
            this.outcomes.forEach(outcome => {
                if (outcome.isFilled()) {
                    colors.push(outcome.color)                    
                    outcomesRequest.push({
                        name: outcome.display,
                        criteria: {
                            eventCriteria: [outcome.getForAPI(startDate, endDate)]
                        }
                    })
                }
            })
            report.groups = [patientGroups, outcomesRequest];
            report.colors = colors
            return report;
        },
    }
})
